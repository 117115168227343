<template>
  <div>
    <a-modal
      :footer="null"
      title="动态库管理"
      :visible="visible"
      width="100%"
      wrapClassName="product-configuration-full-modal"
      @cancel="closeModel"
    >
      <a-skeleton active v-if="loading" />
      <div v-else>
        <a-descriptions bordered>
          <a-descriptions-item label="动态库"> {{ description || '-' }} </a-descriptions-item>
          <a-descriptions-item label="so文件名"> {{ listRequest.soFilename }} </a-descriptions-item>
        </a-descriptions>
        <a-card style="margin-top: 1%">
          <a-form-model v-model="listRequest" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-row :gutter="[24, 16]" type="flex" align="bottom">
              <a-col style="width: 20%">
                <a-form-model-item label="中控型号">
                  <a-select
                    show-search
                    placeholder="请选择中控型号"
                    allow-clear
                    v-model="listRequest.model"
                    @change="handleCpuModelChange"
                  >
                    <a-select-option v-for="cpuModel in cpuModels" :key="cpuModel.model" :value="cpuModel.model">{{
                      cpuModel.model
                    }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col style="width: 20%">
                <a-form-model-item label="硬件版本">
                  <a-select
                    show-search
                    v-model="listRequest.hardVersion"
                    allow-clear
                    :placeholder="listRequest.model ? '请选择硬件版本' : '请先选择中控型号'"
                  >
                    <a-select-option
                      v-for="hardVersion in hardVersions"
                      :key="hardVersion.hardVersion"
                      :value="hardVersion.hardVersion"
                    >
                      {{ hardVersion.hardVersion }}</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col style="width: 20%">
                <a-form-model-item>
                  <a-button type="primary" @click="doSearch">查询</a-button>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <a-table
            :data-source="tableData.elements"
            :pagination="false"
            :loading="tableLoading"
            :rowKey="
              (record, index) => {
                return `${record.configurationItemId}${record.soFilename}${record.model}${record.hardVersion}`;
              }
            "
          >
            <a-table-column data-index="model" key="model" title="中控型号" align="center"></a-table-column>
            <a-table-column data-index="hardVersion" key="hardVersion" title="硬件版本" align="center"></a-table-column>
            <a-table-column data-index="productViews" key="productViews" title="物模型" align="center">
              <template slot-scope="text, record">
                <div v-if="record.productViews.length">
                  <a-button
                    @click="jumpToProduct(product.productId)"
                    type="link"
                    size="small"
                    style="margin-right: 1rem"
                    v-for="product in record.productViews"
                    :key="product.id"
                  >
                    {{ product.productName }}</a-button
                  >
                </div>
                <span v-else>-</span>
              </template>
            </a-table-column>
            <a-table-column data-index="status" key="status" title="状态" align="center">
              <template slot-scope="text, record">
                {{ record.path ? '已上传' : '未上传' }}
              </template>
            </a-table-column>
            <a-table-column data-index="sizeInBytes" key="sizeInBytes" title="文件大小(byte)" align="center">
              <template slot-scope="text, record">
                {{ record.sizeInBytes || '-' }}
              </template>
            </a-table-column>
            <a-table-column data-index="md5" key="md5" title="文件MD5" align="center">
              <template slot-scope="text, record">
                {{ record.md5 || '-' }}
              </template>
            </a-table-column>
            <a-table-column data-index="remark" key="remark" title="备注" align="center">
              <template slot-scope="text, record">
                {{ record.remark || '-' }}
              </template>
            </a-table-column>
            <a-table-column key="operator" title="操作" align="center">
              <template slot-scope="text, record">
                <a-button
                  v-if="$hasPermissions(['rcs:dll_file:create', 'rcs:dll_file:update'])"
                  @click="openFileModal(record)"
                  type="link"
                  >{{ record.path ? '重新上传' : '文件上传' }}</a-button
                >
                <a-popconfirm
                  v-if="record.id && $hasPermissions(['rcs:dll_file:delete'])"
                  title="确认删除？"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="handleDelete(record)"
                >
                  <a-button type="link" style="color: red">删除</a-button>
                </a-popconfirm>
              </template>
            </a-table-column>
          </a-table>
          <div style="margin-top: 3%">
            <a-pagination
              :current="tableData.currentPage + 1"
              :show-total="(total) => `共 ${tableData.totalElements} 条`"
              :pageSize="tableData.size"
              :total="tableData.totalElements"
              @change="onChange"
            />
            <br />
          </div>
        </a-card>
      </div>
    </a-modal>
    <UploadProgress ref="uploadProgress" />
    <a-modal
      v-if="fileUploadModalVisible"
      @cancel="closeFileModal"
      @ok="handleFileBind"
      :title="currentRecord.path ? '重新上传' : '文件上传'"
      :visible="fileUploadModalVisible"
    >
      <a-skeleton active v-if="modalLoading"></a-skeleton>
      <a-form-model v-else :model="fileForm" layout="vertical" ref="fileForm">
        <a-row :gutter="[24, 16]" type="flex" align="bottom">
          <a-col style="width: 100%">
            <a-form-model-item label="文件" prop="file">
              <a-upload
                v-if="!fileForm.file"
                :fileList="fileList"
                :beforeUpload="handleBeforeUpload"
                :customRequest="customRequest"
              >
                <a-button type="primary"><a-icon type="inbox" />点击上传</a-button>
              </a-upload>
              <div v-else style="display: flex; align-item: center">
                <a-button type="link">{{ this.fileForm.file.request.file.name }}</a-button>
                <a-button @click="handleClearFile" type="link" style="color: red">x</a-button>
              </div>
            </a-form-model-item></a-col
          >
          <a-col style="width: 100%">
            <a-form-model-item label="备注" prop="remark">
              <a-textarea v-model="fileForm.remark" showCount :maxLength="60" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import UploadProgress from '../../../components/upload-progress/UploadProgress';

export default {
  name: 'ProductConfigurationDynamicDepotModal',
  components: { UploadProgress },
  data() {
    return {
      visible: false,
      modalLoading: false,
      listRequest: {
        configurationItemId: undefined,
        productId: undefined,
        soFilename: undefined,
        identifier: undefined,
        model: undefined,
        hardVersion: undefined,
      },
      tableData: {
        elements: [],
        currentPage: 0,
        size: 5,
        totalElements: 0,
      },
      fileList: [],
      cpuModels: [],
      hardVersions: [],
      description: undefined,
      loading: false,
      tableLoading: false,
      fileUploadModalVisible: false,
      fileForm: {
        file: undefined,
        remark: undefined,
      },
      currentRecord: {
        path: undefined,
      },
    };
  },
  methods: {
    async openFileModal(record) {
      this.modalLoading = true;
      this.fileUploadModalVisible = true;
      try {
        this.currentRecord = await this.fetchDllFile(record);
        this.fileForm.remark = this.currentRecord.remark;
        if (this.currentRecord.filename) {
          Object.assign(this.fileForm, {
            file: {
              filePath: this.currentRecord.path,
              md5: this.currentRecord.md5,
              request: {
                file: {
                  name: this.currentRecord.filename,
                  size: this.currentRecord.sizeInBytes,
                },
              },
            },
          });
        }
      } finally {
        this.modalLoading = false;
      }
    },
    async fetchDllFile(record) {
      const params = {
        configurationItemId: record.configurationItemId,
        soFilename: record.soFilename,
        model: record.model,
        hardVersion: record.hardVersion,
      };
      const res = await this.$apiManager.dynamicDepot.getDLLFile(params);
      return res.data;
    },
    closeFileModal() {
      Object.assign(this.currentRecord, {
        path: undefined,
      });
      Object.assign(this.fileForm, {
        file: undefined,
        remark: undefined,
      });
      this.fileUploadModalVisible = false;
    },
    closeModel() {
      Object.assign(this.listRequest, {
        configurationItemId: undefined,
        productId: undefined,
        soFilename: undefined,
        identifier: undefined,
        model: undefined,
        hardVersion: undefined,
      });
      Object.assign(this.tableData, {
        elements: [],
        currentPage: 0,
        size: 5,
        totalElements: 0,
      });
      this.visible = false;
    },
    async fetchDynamicDepotList() {
      this.tableLoading = true;
      try {
        const params = {
          page: this.tableData.currentPage,
          size: this.tableData.size,
          ...this.listRequest,
        };
        const res = await this.$apiManager.dynamicDepot.getProductConfigurationDLLFiles(params);
        Object.assign(this.tableData, res.data);
      } finally {
        this.tableLoading = false;
      }
    },
    async fetchCpuModel() {
      const res = await this.$apiManager.cpuModel.getCpuModelList({
        unpaged: true,
      });
      this.cpuModels = res.data.elements;
    },
    async fetchHardVersion() {
      const res = await this.$apiManager.cpuHardware.getCpuHardwareList({
        model: this.listRequest.model,
        unpaged: true,
      });
      this.hardVersions = res.data.elements;
    },
    handleCpuModelChange(cpuModel) {
      this.hardVersions = [];
      this.listRequest.hardVersion = undefined;
      if (cpuModel) {
        this.fetchHardVersion();
      }
    },
    doSearch() {
      this.$set(this.tableData, 'currentPage', 0);
      this.fetchDynamicDepotList();
    },
    onChange(pageNumber) {
      this.$set(this.tableData, 'currentPage', pageNumber - 1);
      this.fetchDynamicDepotList();
    },
    async handleDelete(dllFile) {
      this.tableLoading = true;
      try {
        const res = await this.$apiManager.dynamicDepot.deleteDLLFiles(dllFile.id);
        if (res.data.success) {
          this.$message.success('删除成功');
          await this.fetchDynamicDepotList();
        }
      } finally {
        this.tableLoading = false;
      }
    },
    handleBeforeUpload(file) {
      this.fileList = [];
      const MAX_FILE_SIZE = 500 * 1024;
      const MAX_FILE_NAME_LENGTH = 64;
      const fileNameRegex = /^[^\u4e00-\u9fa5]+\.(so)$/;
      if (!fileNameRegex.test(file.name)) {
        this.$message.info('文件名不能包含中文且文件后缀为.so');
        return false;
      }
      if (file.name.length > MAX_FILE_NAME_LENGTH) {
        this.$message.info(`文件名称最大长度为${MAX_FILE_NAME_LENGTH}`);
        return false;
      }
      if (file.size > MAX_FILE_SIZE) {
        this.$message.info(`文件大小超过${MAX_FILE_SIZE / 1024}KB`);
        return false;
      }
      return true;
    },
    customRequest(request) {
      // 上传文件
      this.$refs.uploadProgress.visible = true;
      this.$apiManager.file
        .uploadFile(
          this.$constRepository.file.Module.productConfigurationItem,
          this.$constRepository.file.Submodule.file,
          request.file,
        )
        .then((fileUploadResponse) => {
          if (fileUploadResponse) {
            this.$set(this.fileForm, 'file', fileUploadResponse.data);
            this.$set(this.fileForm.file, 'request', request);
          }
        })
        .finally(() => {
          this.$refs.uploadProgress.visible = false;
          this.$store.commit('setUploadProgress', -1);
        });
    },
    handleFileBind() {
      if (this.fileForm.file) {
        const params = {
          model: this.currentRecord.model,
          hardVersion: this.currentRecord.hardVersion,
          configurationItemId: this.listRequest.configurationItemId,
          soFilename: this.listRequest.soFilename,
          filename: this.fileForm.file.request.file.name,
          path: this.fileForm.file.filePath,
          sizeInBytes: this.fileForm.file.request.file.size,
          md5: this.fileForm.file.md5,
          identifier: this.listRequest.identifier,
          remark: this.fileForm.remark,
        };
        if (!this.currentRecord.path) {
          this.$apiManager.dynamicDepot.bindDLLFiles(params).then((res) => {
            if (res.data) {
              this.$message.success('文件上传成功');
              this.fetchDynamicDepotList();
            }
          });
        } else {
          this.$apiManager.dynamicDepot.updateDLLFiles({ ...params, id: this.currentRecord.id }).then((res) => {
            if (res.data) {
              this.$message.success('文件修改成功');
              this.fetchDynamicDepotList();
            }
          });
        }
        this.closeFileModal();
      } else {
        this.$message.info('请选择上传的文件');
      }
    },
    handleClearFile() {
      this.fileForm.file = undefined;
    },
    async showDialog(params, description) {
      Object.assign(this.listRequest, params);
      this.description = description;
      this.visible = true;
      this.loading = true;
      try {
        await Promise.all([this.fetchDynamicDepotList(), this.fetchCpuModel()]);
      } finally {
        this.loading = false;
      }
    },
    jumpToProduct(id) {
      this.$router.push({
        name: 'productDetail',
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="less">
.product-configuration-full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>
