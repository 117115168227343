<template>
  <a-modal centered :visible="visible" okText="确认" cancelText="取消" @ok="handleOk" @cancel="handleCancel">
    <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" ref="form">
      <a-form-model-item label="value" prop="value">
        <a-input v-model="form.value"> </a-input>
      </a-form-model-item>
      <a-form-model-item v-if="form.description.key" label="key" prop="description.key">
        <a-input disabled v-model="form.description.key"> </a-input>
      </a-form-model-item>
      <a-form-model-item label="defaultMessage" prop="description.defaultMessage">
        <a-input v-model="form.description.defaultMessage"> </a-input>
      </a-form-model-item>
      <a-form-model-item label="file" prop="file">
        <a-upload :customRequest="customRequest" :file-list="form.fileList" :remove="removeFile">
          <a-button v-if="form.fileList.length === 0"> <a-icon type="upload" /> 上传 </a-button>
          <a-button v-else> <a-icon type="upload" /> 修改 </a-button>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="md5" prop="md5">
        <a-input disabled v-model="form.fileInfo.md5"> </a-input>
      </a-form-model-item>
      <a-form-model-item label="filePath" prop="filePath">
        <a-input disabled v-model="form.fileInfo.filePath"> </a-input>
      </a-form-model-item>
    </a-form-model>
    <upload-progress ref="uploadProgress" />
  </a-modal>
</template>

<script>
import UploadProgress from '../../../../components/upload-progress/UploadProgress';

export default {
  name: 'productAddFileTypeModal',
  components: { UploadProgress },
  props: ['currentItem', 'visible'],
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      form: this.currentItem,
      // rules: {
      //   value: [{ required: true, message: 'value 不能为空' }],
      //   // 'description.key': [{ required: true, message: 'key 不能为空' }],
      //   'description.defaultMessage': [{ required: true, message: 'defaultMessage 不能为空' }],
      // },
    };
  },
  methods: {
    handleOk() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          if (this.form.fileList.length === 0) {
            delete this.form.fileInfo;
          }
          delete this.form.fileList;
          this.$emit('editItem', this.form);
          this.$emit('update:visible', false);
        }
      });
    },
    handleCancel() {
      if (this.form.fileList.length === 0) {
        delete this.form.fileInfo;
      }
      delete this.form.fileList;
      this.$emit('update:visible', false);
    },
    async customRequest(request) {
      this.$refs.uploadProgress.visible = true;
      let fileUploadResponse;
      try {
        fileUploadResponse = await this.$apiManager.file.uploadFile(
          this.$constRepository.file.Module.product,
          this.$constRepository.file.Submodule.file,
          request.file,
        );
      } catch (err) {
        this.$message.error('上传失败');
        this.$refs.uploadProgress.visible = false;
        return;
      }
      this.$refs.uploadProgress.visible = false;
      this.$store.commit('setUploadProgress', -1);
      if (fileUploadResponse.data.filePath) {
        const reader = new FileReader();
        reader.readAsDataURL(request.file);
        const params = {
          filePath: fileUploadResponse.data.filePath,
          uid: fileUploadResponse.data.md5,
          status: 'done',
          name: request.file.name,
        };
        reader.onloadend = (e) => {
          const url = e.target.result;
          params.url = url;
          // 清除旧文件
          this.form.fileList = [];
          this.form.fileList.push(params);
        };
        this.form.fileInfo = {
          md5: fileUploadResponse.data.md5,
          filePath: fileUploadResponse.data.filePath,
          originalFilename: request.file.name,
        };
      }
    },
    removeFile(record) {
      this.form.fileList = this.form.fileList.filter((file) => file !== record);
      this.form.fileInfo = {};
    },
  },
};
</script>
